import { BehaviorSubject } from "rxjs";
import { HttpClientService } from "src/app/http-confing/http/http-client.service";
import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class AdminProfileService {
  updatedName = new BehaviorSubject("");

  constructor(private http: HttpClientService) {}

  getAdminProfile() {
    return this.http.get("admin/profile");
  }
  updaateProfile(body) {
    return this.http.post(body, "admin/edit");
  }
}
