import { AuthGuardGuard } from "./core/guards/auth-guard/auth-guard.guard";
import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";

import { LayoutComponent } from "./layouts/layout.component";
import { NonAuthGuardGuard } from "./core/guards/non-auth-guard/non-auth-guard.guard";
import { Page404Component } from "./pages/page404/page404.component";

const routes: Routes = [
  {
    path: "account",
    loadChildren: () =>
      import("./account/account.module").then((m) => m.AccountModule),
    canActivate: [AuthGuardGuard],
  },
  // tslint:disable-next-line: max-line-length
  {
    path: "",
    component: LayoutComponent,
    loadChildren: () =>
      import("./pages/pages.module").then((m) => m.PagesModule),
    canActivate: [NonAuthGuardGuard],
  },

  { path: "404", component: Page404Component },
  { path: "**", redirectTo: "404" },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: "top",
      relativeLinkResolution: "legacy",
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
