<footer class="footer">
    <div class="container-fluid">
        <div class="row">
            <div class="col-sm-6">
                <!-- {{year}} © Skote. -->
            </div>
            <div class="col-sm-6">
                <div class="text-sm-end d-none d-sm-block">
                    {{"Develop by Digital Creativity" | translate}}
                </div>
            </div>
        </div>
    </div>
</footer>