import { AdminProfileService } from "src/app/core/services/admin-profile/admin-profile.service";
import { DisplayNotificationsService } from "./../../core/services/display-notifications/display-notifications.service";
import { Observable } from "rxjs";
import { MessagingService } from "./../../core/services/notification/notification.service";
import { Component, OnInit, Output, EventEmitter, Inject } from "@angular/core";
import { Router } from "@angular/router";
import { DOCUMENT } from "@angular/common";
import { AuthenticationService } from "../../core/services/auth/auth.service";
import { CookieService } from "ngx-cookie-service";
import { LanguageService } from "../../core/services/language.service";
import { TranslateService } from "@ngx-translate/core";
import Swal from "sweetalert2";

@Component({
  selector: "app-topbar",
  templateUrl: "./topbar.component.html",
  styleUrls: ["./topbar.component.scss"],
})

/**
 * Topbar component
 */
export class TopbarComponent implements OnInit {
  element;
  cookieValue;
  flagvalue;
  countryName;
  valueset;
  currentuser;
  keyword;
  isAdmin = false;
  message;
  notifications = [];
  readNotifications = [];
  public unreadNotifications$: Observable<number>;
  allNotifications = [];
  canView: boolean;

  constructor(
    @Inject(DOCUMENT) private document: any,
    private router: Router,
    private authService: AuthenticationService,
    public languageService: LanguageService,
    public translate: TranslateService,
    public _cookiesService: CookieService,
    // private displayNotifi: DisplayNotificationsService,
    private messagingService: MessagingService,
    private adminService: AdminProfileService
  ) {
    this.messagingService.currentMessage.subscribe((msg: any) => {
      this.message = msg;
      // this.unreadNotifications$ = this.displayNotifi.unreadNotificationsSubject;
    });
  }

  listLang = [
    { text: "English", flag: "assets/images/flags/us.jpg", lang: "en" },

    {
      text: "العربية",
      flag: "assets/images/flags/saudi-arabia.jpg",
      lang: "ar",
    },
  ];

  openMobileMenu: boolean;

  @Output() settingsButtonClicked = new EventEmitter();
  @Output() mobileMenuButtonClicked = new EventEmitter();

  ngOnInit() {
    if (
      this.messagingService.firebaseToken &&
      !this.authService.User.firebase_token
    ) {
      const user = JSON.parse(localStorage.getItem("user"));
      user.firebase_token = this.messagingService.firebaseToken;
      localStorage.setItem("user", JSON.stringify(user));
    }

    // this.getNotifications();

    this.openMobileMenu = false;
    this.element = document.documentElement;

    this.cookieValue = this._cookiesService.get("lang");
    const val = this.listLang.filter((x) => x.lang === this.cookieValue);
    this.countryName = val.map((element) => element.text);
    if (val.length === 0) {
      if (this.flagvalue === undefined) {
        this.valueset = "assets/images/flags/us.jpg";
      }
    } else {
      this.flagvalue = val.map((element) => element.flag);
    }

    localStorage.setItem("lang", this.cookieValue);
    if (this.cookieValue === "en") {
      document.getElementsByTagName("html")[0].setAttribute("dir", "ltr");
      document.getElementsByTagName("html")[0].setAttribute("lang", "en");
      document.body.classList.add("en");
      document.body.classList.remove("ar");
    } else {
      document.getElementsByTagName("html")[0].setAttribute("dir", "rtl");
      document.getElementsByTagName("html")[0].setAttribute("lang", "ar");
      document.body.classList.add("ar");
      document.body.classList.remove("en");
    }
    this.getUpdatedProfile();
    if (this.authService.User.user_type == "admin") {
      this.canView = this.authService.User.permissions.includes("view_offers");
      this.isAdmin = true;
    }
  }

  getUpdatedProfile() {
    this.adminService.updatedName.subscribe((res) => {
      if (res) {
        this.currentuser = res;
      } else {
        this.currentuser = this.authService.User;
      }
    });
  }
  setLanguage(text: string, lang: string, flag: string) {
    this.countryName = text;
    this.flagvalue = flag;
    this.cookieValue = lang;
    this.languageService.setLanguage(lang);
    this._cookiesService.set("lang", lang);
    localStorage.setItem("lang", this.cookieValue);

    if (lang === "en") {
      document.getElementsByTagName("html")[0].setAttribute("dir", "ltr");
      document.getElementsByTagName("html")[0].setAttribute("lang", "en");
      document.body.classList.add("en");
      document.body.classList.remove("ar");
    } else {
      document.getElementsByTagName("html")[0].setAttribute("dir", "rtl");
      document.getElementsByTagName("html")[0].setAttribute("lang", "ar");
      document.body.classList.add("ar");
      document.body.classList.remove("en");
    }
  }

  /**
   * Toggles the right sidebar
   */
  toggleRightSidebar() {
    this.settingsButtonClicked.emit();
  }

  /**
   * Toggle the menu bar when having mobile screen
   */
  toggleMobileMenu(event: any) {
    event.preventDefault();
    this.mobileMenuButtonClicked.emit();
  }

  /**
   * Logout the user
   */
  logout() {
    this.authService.logout();
    this.router.navigate(["/account/login"]);
  }

  /**
   * Fullscreen method
   */
  fullscreen() {
    document.body.classList.toggle("fullscreen-enable");
    if (
      !document.fullscreenElement &&
      !this.element.mozFullScreenElement &&
      !this.element.webkitFullscreenElement
    ) {
      if (this.element.requestFullscreen) {
        this.element.requestFullscreen();
      } else if (this.element.mozRequestFullScreen) {
        /* Firefox */
        this.element.mozRequestFullScreen();
      } else if (this.element.webkitRequestFullscreen) {
        /* Chrome, Safari and Opera */
        this.element.webkitRequestFullscreen();
      } else if (this.element.msRequestFullscreen) {
        /* IE/Edge */
        this.element.msRequestFullscreen();
      }
    } else {
      if (this.document.exitFullscreen) {
        this.document.exitFullscreen();
      } else if (this.document.mozCancelFullScreen) {
        /* Firefox */
        this.document.mozCancelFullScreen();
      } else if (this.document.webkitExitFullscreen) {
        /* Chrome, Safari and Opera */
        this.document.webkitExitFullscreen();
      } else if (this.document.msExitFullscreen) {
        /* IE/Edge */
        this.document.msExitFullscreen();
      }
    }
  }

  search() {
    this.router.navigate(["/offers"], { queryParams: { title: this.keyword } });
    this.keyword = "";
  }

  //Notification Section

  // private getNotifications() {
  //   this.displayNotifi.notificationsSubject.subscribe((res) => {
  //     if (res) {
  //       this.allNotifications = res;
  //       this.setUnreadAndRead(res);
  //     }
  //   });
  // }

  private setUnreadAndRead(allNotifications) {
    this.notifications = allNotifications.filter(
      (notification) => notification.read_at === null
    );
    this.readNotifications = allNotifications.filter(
      (notification) => notification.read_at !== null
    );
  }

  // markAllNotificationsAsRead() {
  //   Swal.fire({
  //     title: "هل انت متأكد؟",
  //     text: "سوف تقوم بجعل جميع الاشعارات مقروئه",
  //     icon: "warning",
  //     showCancelButton: true,
  //     confirmButtonText: "نعم, استمر",
  //     cancelButtonText: "لا, اغلق",
  //   }).then((result) => {
  //     if (result.value) {
  //       Swal.fire("تم بنجاح", "تم جعل جميع الاشعارات مقروئه", "success");
  //       this.displayNotifi.markAllNotificationsAsRead();
  //       if (this.notifications.length) {
  //         this.notifications.forEach((notification) => {
  //           notification.read_at = 1;
  //         });
  //         this.setUnreadAndRead(this.allNotifications);
  //       }
  //     } else if (result.dismiss === Swal.DismissReason.cancel) {
  //       Swal.fire("تم التوقف", "لم يحدث اى تغيير فى اشعاراتك", "error");
  //     }
  //   });
  // }

  // loadMoreNotifications() {
  //   this.displayNotifi.loadMoreNotifications();
  // }

  // markNotificationAsRead(notificationId: string) {
  //   this.displayNotifi.markNotificationAsRead(notificationId);
  // }

  getNotificationDate(date: string): string {
    return (
      new Date(date).toLocaleTimeString() +
      ", " +
      new Date(date).toLocaleDateString()
    );
  }

  // public get seeMore() {
  //   return this.displayNotifi.seeMore;
  // }

  notificationTypes(type, id) {
    if (type == "support") {
      this.router.navigate(["/support"], { queryParams: { currentTap: "1" } });
    } else if (type == "join_request") {
      this.router.navigate(["/joinrequests"]);
    } else if (type == "offer") {
      this.router.navigate(["/offers"]);
    }
  }
}
