import { MessagingService } from "./../notification/notification.service";
import { Router } from "@angular/router";
import { Subject } from "rxjs";
import { HttpClientService } from "../../../http-confing/http/http-client.service";
import { Injectable } from "@angular/core";
import { User } from "../../models/auth.models";

@Injectable({ providedIn: "root" })
export class AuthenticationService {
  public redirectUrl: string;
  public asCompany: number;
  public expiredTokenSubject: Subject<boolean>;

  constructor(
    private http: HttpClientService,
    private router: Router,
    private messagingService: MessagingService
  ) {
    this.expiredTokenSubject = new Subject();
  }

  public set User(user) {
    user.firebase_token = this.messagingService.firebaseToken
      ? this.messagingService.firebaseToken
      : "Not Allowed";
    localStorage.setItem("user", JSON.stringify(user));
  }

  public get User(): User {
    return JSON.parse(localStorage.getItem("user"));
  }

  public get Token() {
    if (this.User) {
      return this.User.token;
    }
  }

  public get isLoggedIn() {
    if (!this.User) {
      return false;
    }

    return true;
  }

  public get getAdmin() {
    return "admin";
  }

  public isAdmin() {
    if (this.User && this.User.user_type === "admin") {
      return true;
    }

    return false;
  }

  public logout() {
    const body: { firebase_token?: string } = {};
    if (this.messagingService.firebaseToken) {
      body.firebase_token = this.messagingService.firebaseToken;
    }
    this.http.post(body, "auth/logout").subscribe(
      (_) => this.removeUser(),
      (_) => this.removeUser()
    );
  }

  removeUser() {
    localStorage.removeItem("user");
    this.router.navigate(["account/login"]);
  }

  public Login(body: {
    field: string;
    password: string;
    firebase_token: string;
  }) {
    if (this.messagingService.firebaseToken) {
      body.firebase_token = this.messagingService.firebaseToken;
    }
    return this.http.post(body, "admins/login");
  }

  public companyRegister(body) {
    return this.http.postFormData(body, "auth/register");
  }

  public verifyCode(body: {
    field: string;
    code: number;
    firebase_token: string;
  }) {
    if (this.messagingService.firebaseToken) {
      body.firebase_token = this.messagingService.firebaseToken;
    }
    return this.http.post(body, "auth/verify");
  }

  public tempRegister(body) {
    return this.http.post(body, "auth/temp-register");
  }
  public forgetPassword(body) {
    return this.http.post(body, "auth/forget-password");
  }
  public resetPassword(body) {
    return this.http.post(body, "auth/reset-password");
  }
}
