import {
  ICityData,
  ICountryData,
  IdistrictData,
} from "./../../models/regions.models";
import { HttpClientService } from "./../../../http-confing/http/http-client.service";
import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class RegionsService {
  constructor(private http: HttpClientService) {}

  getAllCountries(ICountryData: ICountryData) {
    return this.http.get("regions/countries", ICountryData);
  }
  createCountry(body) {
    return this.http.postFormData(body, "regions/countries");
  }
  getSingleCountry(countryId: number) {
    return this.http.get(`regions/countries/${countryId}`);
  }
  updateCountry(countryId: number, body: any) {
    return this.http.postFormData(body, `regions/countries/${countryId}`);
  }
  deleteCountry(countryId: number) {
    return this.http.delete({}, `regions/countries/${countryId}`);
  }

  reOrderCountry(body) {
    return this.http.post(body, "regions/countries/reorder");
  }
  getAllCities(ICityData: ICityData) {
    return this.http.get("regions/cities", ICityData);
  }
  createCities(body) {
    return this.http.postFormData(body, "regions/cities");
  }
  updateCity(cityId: number, body) {
    return this.http.postFormData(body, `regions/cities/${cityId}`);
  }
  deleteCity(cityId: number) {
    return this.http.delete({}, `regions/cities/${cityId}`);
  }
  reOrderCity(body) {
    return this.http.post(body, "regions/cities/reorder");
  }
  getCityByCountry(countryId: number) {
    return this.http.get(`regions/cities/country/${countryId}`, {
      return_all: 1,
    });
  }
  getSingleCity(cityId: number) {
    return this.http.get(`regions/cities/${cityId}`);
  }

  getAllDistricts(IdistrictData: IdistrictData) {
    return this.http.get("regions/regions", IdistrictData);
  }
  createDistrict(body) {
    return this.http.postFormData(body, "regions/regions");
  }
  getSingleDistrict(districtId: number) {
    return this.http.get(`regions/regions/${districtId}`);
  }
  updateDistrict(districtId: number, body: any) {
    return this.http.postFormData(body, `regions/regions/${districtId}`);
  }

  deleteDistrict(districtId: number) {
    return this.http.delete({}, `regions/regions/${districtId}`);
  }
}
