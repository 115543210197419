import { FormsModule } from "@angular/forms";
import { AgmCoreModule } from "@agm/core";
import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";

import { SimplebarAngularModule } from "simplebar-angular";
import { NgbDropdownModule } from "@ng-bootstrap/ng-bootstrap";
import { ClickOutsideModule } from "ng-click-outside";

import { UIModule } from "../shared/ui/ui.module";
import { LayoutComponent } from "./layout.component";
import { SidebarComponent } from "./sidebar/sidebar.component";
import { TopbarComponent } from "./topbar/topbar.component";
import { FooterComponent } from "./footer/footer.component";
import { RightsidebarComponent } from "./rightsidebar/rightsidebar.component";
import { HorizontalComponent } from "./horizontal/horizontal.component";
import { VerticalComponent } from "./vertical/vertical.component";
import { HorizontaltopbarComponent } from "./horizontaltopbar/horizontaltopbar.component";
import { LanguageService } from "../core/services/language.service";
import { TranslateModule } from "@ngx-translate/core";
import { ToastModule } from "primeng/toast";

@NgModule({
  // tslint:disable-next-line: max-line-length
  declarations: [
    LayoutComponent,
    SidebarComponent,
    TopbarComponent,
    FooterComponent,
    RightsidebarComponent,
    HorizontalComponent,
    VerticalComponent,
    HorizontaltopbarComponent,
  ],
  imports: [
    CommonModule,
    TranslateModule,
    RouterModule,
    NgbDropdownModule,
    ClickOutsideModule,
    UIModule,
    FormsModule,
    ToastModule,
    SimplebarAngularModule,
    AgmCoreModule.forRoot({
      // please get your own API key here:
      // https://developers.google.com/maps/documentation/javascript/get-api-key?hl=en
      //todo we need to change that map key
      apiKey: "AIzaSyARqlHkdw49HZhMluWyA2OjZIPjWUSO-PE",
      libraries: ["places"],
    }),
  ],
  providers: [LanguageService],
})
export class LayoutsModule {}
